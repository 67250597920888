import { Ref, forwardRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import XOutlineIcon from "@trustana/icons/react/outline/XOutlineIcon";
import { BaseProps, LabelProps, withLabel } from "@trustana/pattern-library";
import { useForm } from "@utils/hooks";
import { InputFooter } from "../input/inputFooter";
import styles from "./input.module.scss";

export interface InputProps extends LabelProps, BaseProps {
  id?: string;
  value: string;
  placeholder?: string;
  inputClassName?: string;
  disabled?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  readOnly?: boolean;
  validate?: ReturnType<typeof useForm>["validate"];
  autoComplete?: string;
  suffixItem?: React.ReactNode;
  enableClear?: boolean;
  onClearInput?: () => void;
  helperText?: string;
}
// controlled input component
export const Component = forwardRef(
  (
    {
      id,
      validate,
      placeholder,
      inputClassName,
      onChange,
      onBlur,
      disabled,
      readOnly,
      value,
      autoComplete,
      suffixItem,
      enableClear,
      onClearInput,
      helperText,
    }: InputProps,
    ref: Ref<HTMLInputElement>
  ) => {
    const [error, setError] = useState("");
    const [focus, setFocus] = useState(false);
    const { i18n } = useTranslation();

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (id && error && validate) {
        const message = validate(id, value);
        setError(message);
      }
      if (onChange) onChange(e);
    };

    const handleOnFocus = () => {
      setFocus(true);
    };

    const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
      if (id && validate) {
        const message = validate(id, value);
        setError(message);
      }
      setFocus(false);
      if (onBlur) onBlur(e);
    };

    const handleClickIcon = (e: React.MouseEvent) => {
      e.stopPropagation();
      if (onClearInput) onClearInput();
    };

    useEffect(() => {
      if (id && validate && error) {
        const message = validate(id, value);
        setError(message);
      }
    }, [i18n.language]);

    return (
      <>
        <div
          className={clsx(styles.inputContainer, {
            [styles.disabled]: disabled,
            [styles.error]: error,
            [styles.focus]: focus,
          })}
        >
          <input
            id={id}
            value={value}
            disabled={disabled}
            type="text"
            className={clsx(
              styles.inputBaseStyle,
              error ? styles.inputError : styles.input,
              inputClassName
            )}
            placeholder={placeholder}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onFocus={handleOnFocus}
            readOnly={readOnly}
            ref={ref}
            autoComplete={autoComplete}
          />

          {(enableClear || suffixItem) && (
            <div className={styles.suffixItem}>
              {enableClear && (
                <XOutlineIcon
                  tabIndex={0}
                  role="button"
                  className={styles.clearButton}
                  onClick={(e: React.MouseEvent) => {
                    handleClickIcon(e);
                  }}
                />
              )}
              {suffixItem && suffixItem}
            </div>
          )}
        </div>

        <InputFooter
          errorMessage={error}
          error={Boolean(error)}
          helperText={helperText}
        />
      </>
    );
  }
);

const WrappedComponent = withLabel(Component);

export const FormInput = forwardRef(
  (args: InputProps, ref: Ref<HTMLInputElement>) => (
    <div className={clsx(args.className)}>
      <WrappedComponent {...args} ref={ref} />
    </div>
  )
);

Component.displayName = "Input";
FormInput.displayName = "FormInput";
