export const adminRoutes = {
  home: `/home`,
  products: {
    base: "/product/",
    index: "/product/management",
    view: (id: string) => `/product/${id}`,
    management: "/product/management",
    upload: {
      index: "/product/upload",
      import: "/product/upload/import",
    },
    edit: {
      index: "/product/edit",
      id: (id: string) => `/product/edit/${id}`,
    },
  },
  accountManagement: {
    index: "/account-management",
    id: (id: string) => `/account-management/${id}`,
  },
  uploadTool: {
    index: "/upload-tool",
    upload: "/upload-tool/upload",
  },
  suppliers: {
    index: "/suppliers",
    id: (id: string) => `/suppliers/${id}`,
  },
  login: "/login",
  logout: "/logout",
  register: {
    index: "/register",
    success: {
      email: (email: string) => `/register/success/${email}`,
    },
    verify: {
      token: (token: string) => `/register/verify/${token}`,
    },
  },
  attribute: {
    index: "/attributes",
  },
  compliance: {
    index: "/compliance",
  },
  playground: {
    chatbot: "/playground/chatbot",
    imageInfoExtraction: "/playground/image-info-extraction",
    orderProductMapping: "/playground/order-product-mapping",
    googleCategory: "/playground/google-category",
    webInfoExtraction: "/playground/web-info-extraction",
    fineTuning: "/playground/fine-tuning",
    fineTuningJobCreation: `/playground/fine-tuning/job/create`,
    factualData: "/playground/factual-data",
    ocr: "/playground/ocr",
    crawler: "/playground/crawler",
    enrichment: "/playground/enrichment",
    enrichmentHistory: "/playground/enrichment-history",
    enrichmentReprompt: "/playground/enrichment-reprompt",
    enrichmentHistoryDetail: (id: string) =>
      `/playground/enrichment-history/${id}`,
    dataSource: "/playground/data-source",
    excelAnalyzer: "/playground/excel-analyzer",
    barcode: "/playground/barcode",
    staticPageGenerator: "/playground/static-page-generator",
    slidesCreator: "/playground/slides-creator",
    knowledgeBase: "/playground/knowledge-base",
    antdComponents: "/playground/antd-components",
  },
  damTools: {
    resizeImage: "/dam-playground/resize-image",
    attributesGeneration: "/dam-playground/attributes-generation",
    removeBackground: "/dam-playground/remove-background",
    nsfwDetection: "/dam-playground/nsfw-detection",
    removeWatermark: "/dam-playground/remove-watermark",
    removeText: "/dam-playground/remove-text",
    replaceBackground: "/dam-playground/replace-background",
    imageUpscaling: "/dam-playground/image-upscaling",
    imageTranslator: "/dam-playground/image-translator",
  },
  featureToggleDemo: {
    index: "/feature-toggle-demo",
  },
  smartDataTransformation: {
    batchFlow: {
      index: "/smart-data-transformation/batch-flow",
      transform: (supplierId: string) =>
        `/smart-data-transformation/batch-flow/${supplierId}`,
      productDetail: (supplierId: string, productId: string) =>
        `/smart-data-transformation/batch-flow/${supplierId}/${productId}`,
    },
    management: {
      index: "/smart-data-transformation/management",
      create: "/smart-data-transformation/management/create",
    },
    largePdfExtraction: "/smart-data-transformation/large-pdf-extraction",
  },
  // settings: "/dashboard/settings", have no this page for now
};

export const restApiRoutes = {
  next: {
    upload: (uploadTag: string) => `/api/upload/${uploadTag}`,
    templateImport: (route: string) => `/api/upload/templateImport/${route}`,
  },

  templateImport: {
    createFile: (baseUrl: string) => `${baseUrl}/product/import/api/file`,
    status: (baseUrl: string) => `${baseUrl}/product/import/api/status`,
  },

  ai: {
    playground: "/api/ai/playground",
  },
};

export const REST_API_BASE_URL = process.env.NEXT_PUBLIC_FUNCTION_URL || "";

export const BASE_BACKEND_RESTFUL_URL = `${process.env.NEXT_PUBLIC_BACKEND_URL}/restful/v1`;
